import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Sidemenu from '../Layout/Sidemenu';
import Topbar from '../Layout/Topbar';
import Footer from '../Layout/Footer';
import { GrCertificate } from "react-icons/gr";
import Select from 'react-select';
import { FaExclamationTriangle, FaCheck } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";
import { Commonhelper } from '../Helpers/Commonhelper';
import { apiurl } from '../Config/Config';
import axios from 'axios';
import JoditEditor from 'jodit-react';

const AddCertificate = () => {

    const navigate = useNavigate();
    const editorRef = useRef(null);

    useEffect(() => {
        if (Commonhelper.checkSession === false) {
            navigate("/login");
        } else {

            let pathname = window.location.pathname;
            let id_array = pathname.split('/');
            let unique_id = id_array[3];
            setuniqueId(unique_id);
            const postObject = {
                unique_id: unique_id
            };

            fetchData(postObject);
            fetchApps();
            fetchTitles();
        }
    }, [navigate]);

    const today = new Date();

    // Get year, month, and day with proper formatting
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(today.getDate()).padStart(2, '0'); // Add leading zero if needed

    const formattedDate = `${year}-${month}-${day}`;

    const [displayLoader, setDisplayLoader] = useState('none');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const [uniqueId, setuniqueId] = useState('');
    const [titleType, setTitleType] = useState('existing');
    const [listCertificateTitles, setListCertificateTitles] = useState([]);
    const [certificateTitle, setCertificateTitle] = useState(null);
    const [certificateTitle2, setCertificateTitle2] = useState('');
    const [selectedDate, setSelectedDate] = useState(formattedDate);
    const [appliedStandards, setAppliedStandards] = useState(null);
    const [reportNumber, setReportNumber] = useState('');
    const [stickerNumber, setStickerNumber] = useState('');
    const [employerNameAddress, setEmployerNameAddress] = useState('');
    const [location, setLocation] = useState('');
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [equipmentDescription, setEquipmentDescription] = useState('');
    const [safeWorking, setSafeWorking] = useState('');
    const [manufacturerName, setManufacturerName] = useState('');
    const [manufactureDate, setManufactureDate] = useState('');

    const [firstExamined, setFirstExamined] = useState('');
    const [installedCorrectly, setInstalledCorrectly] = useState('');
    const [monthsInterval, setMonthsInterval] = useState('');
    const [sixMonthsInterval, setSixMonthsInterval] = useState('');
    const [twelveMonthsInterval, setTwelveMonthsInterval] = useState('');
    const [examScheme, setExamScheme] = useState('');
    const [afterOccur, setAfterOccur] = useState('');
    const [defect, setDefect] = useState('NONE');
    const [defect2, setDefect2] = useState('');
    const [iminentDanger, setIminentDanger] = useState('');
    const [repairRenewal, setRepairRenewal] = useState('');
    const [anyTests, setAnyTests] = useState('NONE');
    const [observation, setObservation] = useState('');
    const [safeToOperate, setSafeToOperate] = useState('');

    const [certTitleError, setCertTitleError] = useState(false);
    const [isDateError, setDateError] = useState(false);
    const [isAppStdError, setAppStdError] = useState(false);
    const [isReportNumberError, setReportNumberError] = useState(false);
    const [isStickerNumberError, setStickerNumberError] = useState(false);
    const [empNameAddError, setEmpNameAddError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [eqpNumError, setEqpNumError] = useState(false);
    const [eqpDescError, setEqpDescError] = useState(false);
    const [safeWorkingError, setSafeWorkingError] = useState(false);
    const [manufacturerNameError, setManufacturerNameError] = useState(false);
    const [manufactureDateError, setManufactureDateError] = useState(false);
    const [defectError, setDefectError] = useState(false);
    const [defect2Error, setDefect2Error] = useState(false);
    const [repairRenewalError, setRepairRenewalError] = useState(false);
    const [anyTestsError, setAnyTestsError] = useState(false);
    const [observationError, setObservationError] = useState(false);
    const [listAppliedStandards, setListAppliedStandards] = useState([]);

    const options = ['bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',];
    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: '',
            defaultActionOnPaste: 'insert_as_html',
            defaultLineHeight: 1.5,
            enter: 'div',
            // options that we defined in above step.
            buttons: options,
            buttonsMD: options,
            buttonsSM: options,
            buttonsXS: options,
            statusbar: false,
            sizeLG: 900,
            sizeMD: 700,
            sizeSM: 400,
            toolbarAdaptive: false,
        }),
        [],
    );

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrorFree();

        if (certificateTitle === null || certificateTitle === false) {
            setCertTitleError(true);
            setErrorMsg('Select a title');
            scrollToTop();
            return false;
        }

        if (selectedDate === null || selectedDate === false) {
            setDateError(true);
            setErrorMsg('Select a date');
            scrollToTop();
            return false;
        }

        if (appliedStandards === null || appliedStandards === false || appliedStandards === "") {
            setAppStdError(true);
            setErrorMsg('Enter the applied standards');
            scrollToTop();
            return false;
        }

        if (reportNumber === "" || reportNumber === "ESICO-LFT-") {
            setReportNumberError(true);
            setErrorMsg('Enter the report number');
            scrollToTop();
            return false;
        }

        if (stickerNumber === "") {
            setStickerNumberError(true);
            setErrorMsg('Enter the sticker number');
            scrollToTop();
            return false;
        }

        if (employerNameAddress === "") {
            setEmpNameAddError(true);
            setErrorMsg('Enter the employer name and address');
            scrollToTop();
            return false;
        }

        if (location === "") {
            setLocationError(true);
            setErrorMsg('Enter the location');
            scrollToTop();
            return false;
        }

        if (equipmentNumber === "") {
            setEqpNumError(true);
            setErrorMsg('Enter the equipment identification number');
            scrollToTop();
            return false;
        }

        if (equipmentDescription === "") {
            setEqpDescError(true);
            setErrorMsg('Enter the equipment description');
            scrollToTop();
            return false;
        }

        if (safeWorking === "") {
            setSafeWorkingError(true);
            setErrorMsg('Enter the safe working load(s)');
            scrollToTop();
            return false;
        }

        if (manufacturerName === "") {
            setManufacturerNameError(true);
            setErrorMsg('Enter the manufacturer name');
            scrollToTop();
            return false;
        }

        if (manufactureDate === "") {
            setManufactureDateError(true);
            setErrorMsg('Enter the manufacture date');
            scrollToTop();
            return false;
        }

        if (defect === "") {
            setDefectError(true);
            setErrorMsg('Enter description of the defect');
            scrollToTop();
            return false;
        }

        // if (defect2 === "") {
        //     setDefect2Error(true);
        //     setErrorMsg('State the date by when');
        //     scrollToTop();
        //     return false;
        // }

        // if (repairRenewal === "") {
        //     setRepairRenewalError(true);
        //     setErrorMsg('Enter the repair renewal');
        //     scrollToTop();
        //     return false;
        // }

        // if (anyTests === "") {
        //     setAnyTestsError(true);
        //     setErrorMsg('Enter the tests carried out');
        //     scrollToTop();
        //     return false;
        // }

        if (observation === "") {
            setObservationError(true);
            setErrorMsg('Enter the observation');
            scrollToTop();
            return false;
        }

        const postObject = {
            uniqueId: uniqueId,
            titleType: titleType,
            certificateTitle: certificateTitle,
            certificateTitle2: certificateTitle2,
            selectedDate: selectedDate,
            appliedStandards: appliedStandards,
            reportNumber: reportNumber,
            stickerNumber: stickerNumber,
            employerNameAddress: employerNameAddress,
            location: location,
            equipmentNumber: equipmentNumber,
            equipmentDescription: equipmentDescription,
            safeWorking: safeWorking,
            manufacturerName: manufacturerName,
            manufactureDate: manufactureDate,
            firstExamined: firstExamined,
            installedCorrectly: installedCorrectly,
            monthsInterval: monthsInterval,
            sixMonthsInterval: sixMonthsInterval,
            twelveMonthsInterval: twelveMonthsInterval,
            examScheme: examScheme,
            afterOccur: afterOccur,
            defect: defect,
            defect2: defect2,
            iminentDanger: iminentDanger,
            repairRenewal: repairRenewal,
            anyTests: anyTests,
            observation: observation,
            safeToOperate: safeToOperate
        };

        postData(postObject);
    }

    const postData = async (postObject) => {
        setDisplayLoader('inline-block');
        var qs = require('qs');
        try {
            const response = await axios.post(apiurl + 'certificates/update', qs.stringify(postObject));
            if (response && response.data.status === "success") {
                setErrorFree();
                setSuccessMsg(response.data.message);
                scrollToTop();
            } else {
                setErrorFree();
                setErrorMsg(response.data.message);
                scrollToTop();
            }
        } catch (err) {
            setErrorMsg(err);
        }
    }

    const fetchApps = async () => {
        try {
            const response = await axios.get(apiurl + 'appliedstandards/list_applied_standards');
            const responseData = response.data.data;
            setListAppliedStandards(responseData);
        } catch (err) {

        }
    }

    const fetchTitles = async () => {
        try {
            const response = await axios.get(apiurl + 'certificates/get_certificate_titles');
            const responseData = response.data.data;
            setListCertificateTitles(responseData);
        } catch (err) {

        }
    }

    const fetchData = async (postObject) => {
        var qs = require('qs');
        try {
            const response = await axios.post(apiurl + 'certificates/get_details', qs.stringify(postObject));
            if (response && response.data.status === "success") {
                const responseData = response.data.data;
                setTitleType("existing");
                setCertificateTitle({ "label": responseData.certificate_title, "value": responseData.certificate_title });
                setCertificateTitle2(responseData.certificate_title);
                setSelectedDate(responseData.selected_date);
                setAppliedStandards(responseData.applied_standards);
                // setAppliedStandards({ "label": responseData.as_name, "value": responseData.applied_standards });
                setReportNumber(responseData.report_number);
                setStickerNumber(responseData.sticker_number);
                setEmployerNameAddress(responseData.employer_name_address);
                setLocation(responseData.location);
                setEquipmentNumber(responseData.equipment_id);
                setEquipmentDescription(responseData.equipment_description);
                setSafeWorking(responseData.safe_working_loads);
                setManufacturerName(responseData.manufacturer_name);
                setManufactureDate(responseData.manufacture_date);
                setFirstExamined(responseData.first_examined);
                setInstalledCorrectly(responseData.installed_correctly);
                setMonthsInterval(responseData.months_interval);
                setSixMonthsInterval(responseData.six_months_interval);
                setTwelveMonthsInterval(responseData.twelve_months_interval);
                setExamScheme(responseData.exam_scheme);
                setAfterOccur(responseData.after_occur);
                setDefect(responseData.defect);
                setDefect2(responseData.defect2);
                setIminentDanger(responseData.iminent_danger);
                setRepairRenewal(responseData.repair_renewal);
                setAnyTests(responseData.any_tests_carried);
                setObservation(responseData.observation);
                setSafeToOperate(responseData.safe_to_operate);
            }
        } catch (err) {
            setErrorMsg(err);
        }
    };

    const setErrorFree = () => {
        setErrorMsg('');
        setSuccessMsg('');

        setCertTitleError(false);
        setDateError(false);
        setAppStdError(false);
        setReportNumberError(false);
        setStickerNumberError(false);
        setEmpNameAddError(false);
        setLocationError(false);
        setEqpNumError(false);
        setEqpDescError(false);
        setSafeWorkingError(false);
        setManufacturerNameError(false);
        setManufactureDateError(false);
        setDefectError(false);
        setDefect2Error(false);
        setRepairRenewalError(false);
        setAnyTestsError(false);
        setObservationError(false);

        setDisplayLoader('none');
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const deleteAppStd = async (id, name) => {
        let confirm = window.confirm("Are you sure to delete " + name + "?");
        if (confirm) {
            const postObject = {
                id: id
            };

            var qs = require('qs');
            try {
                const response = await axios.post(apiurl + 'appliedstandards/delete', qs.stringify(postObject));
                if (response && response.data.status === "success") {
                    const responseData = response.data.data;
                    setListAppliedStandards(responseData);
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    const updateAppStdWithComma = (newValue) => {
        setAppliedStandards((prevState) => {
            const trimmedPrevState = prevState.trim().replace(/,\s*$/, '');
            const trimmedNewValue = newValue.trim();
            return trimmedPrevState ? `${trimmedPrevState}, ${trimmedNewValue}` : trimmedNewValue;
        });
    };

    return (
        <div className="container-scroller">
            <Topbar />
            <div className="container-fluid page-body-wrapper panel-area">
                <Sidemenu />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white me-2">
                                    <GrCertificate />
                                </span> Edit Certificate
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='err-display' style={{ display: (errorMsg) ? 'block' : 'none' }}> <FaExclamationTriangle /> <p>{errorMsg}</p></div>
                                        <div className='success-display' style={{ display: (successMsg) ? 'block' : 'none' }}> <FaCheck /> <p>{successMsg}</p></div>
                                        <form className="pt-3" onSubmit={handleFormSubmit} autoComplete="off">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <label >Certificate Title</label>
                                                        <div className="col-sm-12">
                                                            <div className="title-type-div">
                                                                <input type='radio' name='titletype' value="existing" onChange={(e) => setTitleType(e.target.value)} checked={titleType === "existing"} /><label>Existing</label>
                                                                <input type='radio' name='titletype' value="new" onChange={(e) => setTitleType(e.target.value)} checked={titleType === "new"} /><label>New</label>
                                                            </div>
                                                            {titleType === "existing" && <Select
                                                                className={certTitleError ? "custom-select err-field" : "custom-select"}
                                                                options={listCertificateTitles}
                                                                value={certificateTitle}
                                                                onChange={(e) => setCertificateTitle(e)}
                                                            />}
                                                            {titleType === "new" &&
                                                                <div className="col-sm-12">
                                                                    <input
                                                                        className={certTitleError ? "form-control err-field" : "form-control"}
                                                                        type="text"
                                                                        onChange={(e) => setCertificateTitle2(e.target.value)}
                                                                        value={certificateTitle2}
                                                                    />
                                                                </div>
                                                            }
                                                            {/* <Select
                                                                className={certTitleError ? "custom-select err-field" : "custom-select"}
                                                                options={Commonhelper.certificate_title}
                                                                value={certificateTitle}
                                                                onChange={(e) => setCertificateTitle(e)}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Date of Thorough Examination</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                className={isDateError ? "form-control err-field" : "form-control"}
                                                                type="date"
                                                                placeholder="dd/mm/yyyy"
                                                                onChange={(e) => setSelectedDate(e.target.value)}
                                                                value={selectedDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Applied Standards</label>
                                                        <div className="col-sm-12">
                                                            {/* <Select
                                                                className={isAppStdError ? "custom-select err-field" : "custom-select"}
                                                                options={Commonhelper.applied_standards}
                                                                value={appliedStandards}
                                                                onChange={(e) => setAppliedStandards(e)}
                                                            /> */}

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                onChange={(e) => setAppliedStandards(e.target.value)}
                                                                value={appliedStandards}
                                                            />
                                                            <div className="app-std-list">
                                                                {
                                                                    listAppliedStandards.length > 0 ? listAppliedStandards.map(item => (
                                                                        <div className="app-std-item" id={item.id} ><div className="item-name" onClick={() => updateAppStdWithComma(item.name)}>{item.name}</div> <div className="item-close"><IoIosCloseCircle onClick={() => deleteAppStd(item.id, item.name)} /></div></div>
                                                                    )) : ""
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Report Number</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                className={isReportNumberError ? "form-control err-field" : "form-control"}
                                                                placeholder="ESICO-LFT-RXX-XXX"
                                                                onChange={(e) => setReportNumber(e.target.value)}
                                                                value={reportNumber}
                                                                style={{ textTransform: "uppercase" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Sticker Number</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                className={isStickerNumberError ? "form-control err-field" : "form-control"}
                                                                onChange={(e) => setStickerNumber(e.target.value)}
                                                                value={stickerNumber}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Name and Address of employer for whom the thorough examination was made</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                type="text"
                                                                className={empNameAddError ? "form-control err-field" : "form-control"}
                                                                onChange={(e) => setEmployerNameAddress(e.target.value)}
                                                                value={employerNameAddress}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Location</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                type="text"
                                                                className={locationError ? "form-control err-field" : "form-control"}
                                                                onChange={(e) => setLocation(e.target.value)}
                                                                value={location}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Equipment Identification Number</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                type="text"
                                                                className={eqpNumError ? "form-control err-field" : "form-control"}
                                                                onChange={(e) => setEquipmentNumber(e.target.value)}
                                                                value={equipmentNumber}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Equipment Description</label>
                                                        <div className="col-sm-12">
                                                            {/* <textarea
                                                                className={eqpDescError ? "form-control err-field" : "form-control"}
                                                                onChange={(e) => setEquipmentDescription(e.target.value)}
                                                                value={equipmentDescription}
                                                            /> */}

                                                            <JoditEditor
                                                                ref={editorRef}
                                                                value={equipmentDescription || ''}
                                                                config={config}
                                                                onChange={(e) => setEquipmentDescription(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Safe Working Load(s)</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                type="text"
                                                                className={safeWorkingError ? "form-control err-field" : "form-control"}
                                                                onChange={(e) => setSafeWorking(e.target.value)}
                                                                value={safeWorking}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Manufacturer Name</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                type="text"
                                                                className={manufacturerNameError ? "form-control err-field" : "form-control"}
                                                                onChange={(e) => setManufacturerName(e.target.value)}
                                                                value={manufacturerName}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Date of Manufacture (if known)</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                type="text"
                                                                className={manufactureDateError ? "form-control err-field" : "form-control"}
                                                                onChange={(e) => setManufactureDate(e.target.value)}
                                                                value={manufactureDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Is this the first examination after installaton or assembly at a new site or location?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="firstExamined" id="firstExamined1" value="Yes" onChange={(e) => setFirstExamined(e.target.value)} checked={firstExamined === "Yes"} /> Yes </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="firstExamined" id="firstExamined2" value="No" onChange={(e) => setFirstExamined(e.target.value)} checked={firstExamined === "No"} /> No </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >If the answer to the previous question is YES, has the equipment been installed correctly?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="installedCorrectly" id="installedCorrectly1" value="Yes" onChange={(e) => setInstalledCorrectly(e.target.value)} checked={installedCorrectly === "Yes"} /> Yes </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="installedCorrectly" id="installedCorrectly2" value="No" onChange={(e) => setInstalledCorrectly(e.target.value)} checked={installedCorrectly === "No"} /> No </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="card-description"> Was the examination carried out </p>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <label >Within an interval of?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="monthsInterval" id="monthsInterval1" value="3" onChange={(e) => setMonthsInterval(e.target.value)} checked={monthsInterval === "3"} /> 3 Months </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="monthsInterval" id="monthsInterval2" value="6" onChange={(e) => setMonthsInterval(e.target.value)} checked={monthsInterval === "6"} /> 6 Months </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="monthsInterval" id="monthsInterval3" value="12" onChange={(e) => setMonthsInterval(e.target.value)} checked={monthsInterval === "12"} /> 12 Months </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Within an interval of 6 months?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="6monthsInterval" id="6monthsInterval1" value="Yes" onChange={(e) => setSixMonthsInterval(e.target.value)} checked={sixMonthsInterval === "Yes"} /> Yes </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="6monthsInterval" id="6monthsInterval2" value="No" onChange={(e) => setSixMonthsInterval(e.target.value)} checked={sixMonthsInterval === "No"} /> No </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Within an interval of 12 months?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="12monthsInterval" id="12monthsInterval1" value="Yes" onChange={(e) => setTwelveMonthsInterval(e.target.value)} checked={twelveMonthsInterval === "Yes"} /> Yes </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="12monthsInterval" id="12monthsInterval2" value="No" onChange={(e) => setTwelveMonthsInterval(e.target.value)} checked={twelveMonthsInterval === "No"} /> No </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >In accordance with an examination scheme?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="examScheme" id="examScheme1" value="Yes" onChange={(e) => setExamScheme(e.target.value)} checked={examScheme === "Yes"} /> Yes </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="examScheme" id="examScheme2" value="No" onChange={(e) => setExamScheme(e.target.value)} checked={examScheme === "No"} /> No </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >After the occurence of exceptional circumstances?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="afterOccur" id="afterOccur1" value="Yes" onChange={(e) => setAfterOccur(e.target.value)} checked={afterOccur === "Yes"} /> Yes </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="afterOccur" id="afterOccur2" value="No" onChange={(e) => setAfterOccur(e.target.value)} checked={afterOccur === "No"} /> No </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group row">
                                                        <label >Identification of any part found to have a defect which is or could become a danger to persons and a description of the defect (if none state NONE)</label>
                                                        <div className="col-sm-12">
                                                            <input type="text" className={defectError ? "form-control err-field" : "form-control"} onChange={(e) => setDefect(e.target.value)} value={defect} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Is the above an existing or imminent danger to persons, Note: This is a reportable defect?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="iminentDanger" id="iminentDanger1" value="Yes" onChange={(e) => setIminentDanger(e.target.value)} checked={iminentDanger === "Yes"} /> Yes </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="iminentDanger" id="iminentDanger2" value="No" onChange={(e) => setIminentDanger(e.target.value)} checked={iminentDanger === "No"} /> No </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Is the above a defect which is not yet could become a danger to persons (if YES state the date by when)</label>
                                                        <div className="col-sm-12">
                                                            <input type="text" className={defect2Error ? "form-control err-field" : "form-control"} onChange={(e) => setDefect2(e.target.value)} value={defect2} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Particulars of any repair, renewal or alteration required to remedy the defect identified above</label>
                                                        <div className="col-sm-12">
                                                            <input type="text" className={repairRenewalError ? "form-control err-field" : "form-control"} onChange={(e) => setRepairRenewal(e.target.value)} value={repairRenewal} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Particulars of any tests carried out as part of the examination (if none state NONE)</label>
                                                        <div className="col-sm-12">
                                                            <input type="text" className={anyTestsError ? "form-control err-field" : "form-control"} onChange={(e) => setAnyTests(e.target.value)} value={anyTests} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Observation / additional comments relative to this thorough examination</label>
                                                        <div className="col-sm-12">
                                                            <textarea className={observationError ? "form-control err-field" : "form-control"} onChange={(e) => setObservation(e.target.value)} value={observation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Is this equipment safe to operate?</label>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="safeToOperate" id="safeToOperate1" value="Yes" onChange={(e) => setSafeToOperate(e.target.value)} checked={safeToOperate === "Yes"} /> Yes </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check">
                                                                <label className="form-check-label">
                                                                    <input type="radio" className="form-check-input" name="safeToOperate" id="safeToOperate2" value="No" onChange={(e) => setSafeToOperate(e.target.value)} checked={safeToOperate === "No"} /> No </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={(displayLoader == 'inline-block' ? 'disabled' : '')} >Submit</button>
                                                <AiOutlineLoading className="loader" display={displayLoader} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default AddCertificate